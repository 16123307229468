'use client';

import { useAuthStore } from '@/stores/AuthStore';

import { GuestHomeTemplate } from './GuestHomeTemplate';
import { HomeRewardRendingBanner } from './HomeRewardRendingBanner';
import { MemberHomeTemplate } from './MemberHomeTemplate';

// import { TopNewsRolling } from './TopNewsRolling';

export const HomePageTemplate = () => {
  const isLogin = useAuthStore((state) => state.isLogin);

  return (
    <>
      {/**
       * //TODO: 추후 커뮤니티 아티클 퀄리티를 보고 노출 결정
       * <TopNewsRolling /> */}
      <section className="flex flex-col pb-60 bg-Gray-100 gap-20">
        <HomeRewardRendingBanner />
        {isLogin ? <MemberHomeTemplate /> : <GuestHomeTemplate />}
      </section>
    </>
  );
};
