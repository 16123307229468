'use client';

import { Skeleton } from '@common/components';
import { SvgChevronright } from '@common/icon';
import { cn } from '@common/utils';
import Image from 'next/image';

import { CustomLink } from '@/components/atom/CustomLink';
import { ROUTES } from '@/constants';
import { useAuthStore } from '@/stores/AuthStore';

import {
  REWARD_RENDING_STATE,
  useRewardRendingState,
} from '../_hooks/useRewardRendingState';

import AllClearImage from './_assets/card/all.png';
import NoClearImage from './_assets/card/no.png';
import OneLeftImage from './_assets/card/one.png';
import SomeClearImage from './_assets/card/some.png';

const CLASS_NAMES = {
  CONTAINER:
    'flex mx-20 items-center gap-16 bg-Gray-white p-20 rounded-large shadow-lg max-w-full overflow-hidden',
  TITLE: 'typo-body3 font-600 text-Gray-900 break-keep',
  DESCRIPTION: 'text-Gray-600 typo-caption1 font-400',
};

export const HomeRewardRendingBanner = () => {
  const { state, benefitCount, leftMission } = useRewardRendingState();
  const nickname = useAuthStore((state) => state.myInfo?.nickName);

  const item = {
    isAttendance: {
      title: '출석체크',
      href: ROUTES.ATTENDANCE_DAILY_CHECK.pathname,
    },
    isInvite: {
      title: '친구 초대',
      href: ROUTES.POINTS_INVITE.pathname,
    },
    isSaveFilter: {
      title: '맞춤 정보 입력',
      href: ROUTES.POLICY_FILTER.pathname({
        situation: 'join',
      }),
    },
    isWrite: {
      title: '글·댓글 작성',
      href: ROUTES.COMMUNITY.pathname,
    },
  }[leftMission || 'isSaveFilter'];

  return {
    [REWARD_RENDING_STATE.LOADING]: (
      <div className={CLASS_NAMES.CONTAINER}>
        <Skeleton className="size-32" />
        <div className="flex-1 gap-8 flex-col flex">
          <Skeleton className="h-20 w-120" />
          <Skeleton className="h-15 w-90" />
        </div>
      </div>
    ),
    [REWARD_RENDING_STATE.GUEST]: (
      <CustomLink
        className={CLASS_NAMES.CONTAINER}
        href={ROUTES.LOGIN.pathname}
      >
        <Image alt="비회원" height={32} src={AllClearImage} width={32} />
        <div>
          <h3 className={CLASS_NAMES.TITLE}>오늘의 웰포인트를 받아보세요!</h3>
          <p className={CLASS_NAMES.DESCRIPTION}>
            {50 <= benefitCount
              ? `지금 ${benefitCount.toLocaleString()}명의 사용자가 혜택을 받았어요.`
              : '친구 초대할 때마다 나도 친구도 500P 적립'}
          </p>
        </div>
        <SvgChevronright className="ml-auto size-20 text-Gray-500" />
      </CustomLink>
    ),
    [REWARD_RENDING_STATE.INITIAL]: (
      <CustomLink
        className={CLASS_NAMES.CONTAINER}
        href={ROUTES.ATTENDANCE_DAILY_CHECK.pathname}
      >
        <Image alt="비회원" height={32} src={NoClearImage} width={32} />
        <div>
          <h3 className={CLASS_NAMES.TITLE}>오늘의 지원금을 놓치고 있어요!</h3>
          <p className={CLASS_NAMES.DESCRIPTION}>
            지금 {benefitCount.toLocaleString()}명의 사용자가 혜택을 받았어요.
          </p>
        </div>
        <SvgChevronright className="ml-auto size-20 text-Gray-500" />
      </CustomLink>
    ),
    [REWARD_RENDING_STATE.DO_SOMETHING]: (
      <CustomLink className={CLASS_NAMES.CONTAINER} href={item.href}>
        <Image alt="비회원" height={32} src={SomeClearImage} width={32} />
        <div>
          <h3 className={CLASS_NAMES.TITLE}>
            오늘 받을 수 있는 웰포인트가 남아있어요!
          </h3>
        </div>
        <SvgChevronright className="ml-auto size-20 text-Gray-500" />
      </CustomLink>
    ),
    [REWARD_RENDING_STATE.LEFT_ONE]: (
      <CustomLink className={CLASS_NAMES.CONTAINER} href={item.href}>
        <Image alt="비회원" height={32} src={OneLeftImage} width={32} />
        <div>
          <h3 className={CLASS_NAMES.TITLE}>
            {item.title}하면 오늘의 웰포인트
            <br />
            모두 받을 수 있어요!
          </h3>
        </div>
        <SvgChevronright className="ml-auto size-20 text-Gray-500" />
      </CustomLink>
    ),
    [REWARD_RENDING_STATE.COMPLETED]: (
      <section className={CLASS_NAMES.CONTAINER}>
        <Image alt="비회원" height={32} src={AllClearImage} width={32} />
        <div className="overflow-hidden">
          <h3 className={CLASS_NAMES.TITLE}>모든 웰포인트를 지급 받았어요!</h3>
          <p
            className={cn(
              CLASS_NAMES.DESCRIPTION,
              'text-nowrap overflow-hidden',
            )}
          >
            <span className="truncate">{nickname}</span>님 대단해요!
          </p>
        </div>
      </section>
    ),
  }[state];
};
