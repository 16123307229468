import type { PolicyCardLoadedProps } from '@/components/molecule/PolicyCard';

import { useQuery } from '@tanstack/react-query';

import { LIKE_TYPE } from '@/api';
import { CustomLink } from '@/components/atom/CustomLink';
import { PolicyCard } from '@/components/molecule/PolicyCard';
import { Skeleton } from '@/components/server';
import { ROUTES } from '@/constants';
import { useLike } from '@/hooks/useLike';
import { policyQueryOptions } from '@/query-factory';

export const RecentViewPolicy = () => {
  const { like } = useLike();

  const { data: policyList, isLoading } = useQuery({
    ...policyQueryOptions.recent(),
    select: (data) =>
      data.context?.policy_list?.map(
        (policy) =>
          ({
            id: policy.id,
            idIdx: policy.meta_policy_id_idx,
            imageUrl: policy.agency_logo,
            name: policy.policy_name,
            provisionList: policy.provision_type_str_list,
            benefit: policy.support_benefit,
            endAt: policy.expiration_date,
            isLike: policy.like_yn,
            dday: policy.dday,
            provisionProcess: policy.provision_process_str,
            likeCount: policy.like_count,
          }) satisfies PolicyCardLoadedProps,
      ),
  });

  const hasNoList = !isLoading && policyList && policyList?.length < 1;

  if (hasNoList) return null;

  if (isLoading)
    return (
      <div className="px-20">
        <Skeleton className="w-170 h-30 my-10 rounded-xsmall" />
        <ul className="flex flex-col gap-8">
          {Array.from({ length: SKELETON_CARD_COUNT }, (_, index) => (
            <PolicyCard key={index} isLoading />
          ))}
        </ul>
      </div>
    );

  return (
    <article className="px-20">
      <h2 className="typo-body1 font-600 text-Gray-black py-10">
        최근 살펴본 정책
      </h2>
      <ul className="flex flex-col gap-8">
        {policyList?.map((policy) => (
          <li key={policy.id}>
            <CustomLink
              href={ROUTES.POLICY_DETAIL.pathname({
                idIdx: policy.idIdx,
              })}
            >
              <PolicyCard
                {...policy}
                likeCount={policy.likeCount}
                onClickLike={(id, isLike, e) => {
                  e.preventDefault();
                  like({
                    contentId: id,
                    isLike: isLike,
                    likeType: LIKE_TYPE.POLICY,
                  });
                }}
              />
            </CustomLink>
          </li>
        ))}
      </ul>
    </article>
  );
};

const SKELETON_CARD_COUNT = 4;
