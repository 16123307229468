import { useQuery } from '@tanstack/react-query';

import { BANNER_CONTENTS_TYPE } from '@/api';
import { CustomLink } from '@/components/atom/CustomLink';
import { NewIcon } from '@/components/atom/NewIcon';
import { LazyImage } from '@/components/client';
import { bannerQueryOptions } from '@/query-factory/banner';

export const ImportantBannerSection = () => {
  const { data } = useQuery(
    bannerQueryOptions.bannerList({
      bannerType: BANNER_CONTENTS_TYPE.HOME_MIDDLE,
    }),
  );

  if (!data?.context?.contents) return null;

  return (
    <section className="flex flex-col px-20">
      <h2 className="typo-body2 font-600 flex items-center gap-8 py-12">
        꼭! 필요한 정보
        <NewIcon />
      </h2>
      <ul className="flex flex-col gap-12">
        {data?.context?.contents?.map(
          ({ file_url, link_url, target_new_yn, title }, index) => {
            if (!(link_url && file_url && title))
              throw new Error('file_url, title is required');

            return (
              <li key={index}>
                <CustomLink
                  aria-label={title}
                  href={link_url}
                  target={target_new_yn ? '_blank' : '_self'}
                >
                  <LazyImage alt={title} className="w-full" src={file_url} />
                </CustomLink>
              </li>
            );
          },
        )}
      </ul>
    </section>
  );
};
