'use client';

import 'swiper/css/pagination';
import type { BANNER_CONTENTS_TYPE } from '@/api';

import { useQuery } from '@tanstack/react-query';
import ReactDOMServer from 'react-dom/server';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { CustomLink } from '@/components/atom/CustomLink';
import { LazyImage } from '@/components/client';
import { bannerQueryOptions } from '@/query-factory/banner';

interface MainCardSliderProps {
  bannerType: BANNER_CONTENTS_TYPE;
}

export const MainBannerSwiper = ({ bannerType }: MainCardSliderProps) => {
  const { data } = useQuery(bannerQueryOptions.bannerList({ bannerType }));

  if (!data?.context?.contents) return null;

  return (
    <section>
      <Swiper
        centeredSlides
        grabCursor
        loop
        autoplay={{
          delay: 5_000,
          disableOnInteraction: false,
        }}
        className="relative"
        modules={[Pagination, Autoplay]}
        pagination={{
          type: 'custom',
          renderCustom: (_, current, total) =>
            ReactDOMServer.renderToString(
              <div className="mb-12 mr-40 ml-auto w-fit typo-caption2 text-Gray-white font-600 bg-Gray-black/20 px-8 py-2 rounded-pill">
                {current} / {total}
              </div>,
            ),
        }}
        slidesPerView={1.1}
        spaceBetween={8}
      >
        {data.context.contents.map(
          ({ file_url, link_url, target_new_yn, title }, index) => {
            if (!(link_url && file_url && title))
              throw new Error('file_url, title is required');

            return (
              <SwiperSlide key={index}>
                <CustomLink
                  aria-label={title}
                  href={link_url}
                  target={target_new_yn ? '_blank' : '_self'}
                >
                  <LazyImage
                    alt={title}
                    className="w-full rounded-mlarge"
                    src={file_url}
                  />
                </CustomLink>
              </SwiperSlide>
            );
          },
        )}
      </Swiper>
    </section>
  );
};
