import CountUp from 'react-countup';

import { Badge, Button } from '@common/components';
import { SvgLike, SvgLikeFill } from '@common/icon';

import { LazyImage } from '@/components/client';
import { Skeleton } from '@/components/server';
import { ROUTES } from '@/constants';

import { CustomLink } from '../atom/CustomLink';

interface PolicyCardBaseProps {
  isLoading?: boolean;
}

interface PolicyCardLoadingProps extends PolicyCardBaseProps {
  isLoading: true;
}

export interface PolicyCardLoadedProps extends PolicyCardBaseProps {
  isLoading?: false;
  id: number;
  idIdx: string;
  imageUrl: string;
  name: string;
  provisionList: string[];
  endAt?: string;
  isLike: boolean;
  onClickLike?: (
    id: number,
    isLike: boolean,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  dday?: string;
  benefit?: string;
  provisionProcess?: string;
  likeCount?: number;
}

type PolicyCardProps = PolicyCardLoadingProps | PolicyCardLoadedProps;

export const PolicyCard = (props: PolicyCardProps) => {
  if (props.isLoading)
    return (
      <div className="flex flex-col px-15 pt-16 pb-14 rounded-medium bg-Gray-white gap-12">
        <div className="flex items-center gap-12">
          <Skeleton className="w-52 h-52 rounded-[20px]" />
          <Skeleton className="w-220 h-23 rounded-xsmall" />
        </div>
        <Skeleton className="w-300 h-23 rounded-xsmall" />
        <Skeleton className="w-250 h-20 rounded-xsmall" />
      </div>
    );

  const {
    id,
    imageUrl,
    name,
    provisionList,
    endAt,
    isLike,
    onClickLike,
    dday,
    benefit,
    provisionProcess,
    likeCount,
  } = props;

  return (
    <CustomLink
      className="flex flex-col px-15 pt-16 pb-14 rounded-medium bg-Gray-white gap-12"
      href={ROUTES.POLICY_DETAIL.pathname({
        idIdx: props.idIdx,
      })}
    >
      <header className="flex items-center gap-12">
        <section className="w-52 h-52 overflow-hidden">
          <LazyImage
            alt={name}
            className="size-52 border border-Gray-300 rounded-large"
            height={52}
            src={imageUrl}
            width={52}
          />
        </section>
        <p className="typo-body3 pr-29 font-500 text-Gray-black line-clamp-2 break-all text-ellipsis flex-1">
          {name}
        </p>
      </header>
      <section className="flex gap-8">
        {dday ? <Badge className="text-R-400">{dday}</Badge> : null}
        {provisionList.map((provision) => (
          <Badge key={provision} className="text-G-600">
            {provision}
          </Badge>
        ))}
        {provisionProcess ? (
          <Badge className="text-Gray-700">{provisionProcess}</Badge>
        ) : null}
      </section>
      <footer className="flex items-center justify-between">
        <section className="flex items-center gap-5 typo-caption1 font-400 text-Gray-600">
          <p className="max-w-139 overflow-hidden text-ellipsis text-nowrap">
            {benefit}
          </p>
          {endAt ? (
            <>
              <div className="w-1 h-10 bg-Gray-400" />
              <p>{endAt}</p>
            </>
          ) : null}
        </section>
        <Button
          className="w-fit h-fit p-0 gap-4"
          theme="sub-text"
          onClick={(e) => {
            e.preventDefault();
            onClickLike?.(id, isLike, e);
          }}
        >
          {isLike ? (
            <SvgLikeFill className="size-16 text-B-500" name="like" />
          ) : (
            <SvgLike className="size-16 text-Gray-600" name="not-like" />
          )}
          {likeCount === undefined ? null : (
            <CountUp
              preserveValue
              className="typo-caption1 text-Gray-600 font-400"
              end={likeCount}
            />
          )}
        </Button>
      </footer>
    </CustomLink>
  );
};
