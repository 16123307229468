import { Badge, Button } from '@common/components';
import { SvgLike, SvgLikeFill } from '@common/icon';
import { cn } from '@common/utils';

import { LazyImage } from '@/components/client';
import { Skeleton } from '@/components/server';

interface PolicyVerticalCardBaseProps {
  isLoading?: boolean;
}

interface PolicyVerticalCardLoadingProps extends PolicyVerticalCardBaseProps {
  isLoading: true;
}

interface PolicyVerticalCardLoadedProps extends PolicyVerticalCardBaseProps {
  isLoading?: false;
  id: number;
  idIdx: string;
  imageUrl: string;
  provisionList: string[];
  name: string;
  isLike: boolean;
  endAt: string;
  dday?: string;
  benefit?: string;
  className?: string;
  onClick?: (idx: string, e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onClickLike?: (id: number, isLike: boolean) => void;
  provisionProcess?: string;
}

export type PolicyVerticalCardProps =
  | PolicyVerticalCardLoadingProps
  | PolicyVerticalCardLoadedProps;

export const PolicyVerticalCard = (props: PolicyVerticalCardProps) => {
  if (props.isLoading)
    return (
      <div className="w-234 h-268 flex flex-col py-16 px-14 justify-between bg-Gray-white rounded-medium">
        <div className="flex flex-col gap-12">
          <Skeleton className="size-52 rounded-mlarge" />
          <div className="flex flex-col gap-7">
            <Skeleton className="w-131 h-23 rounded-xsmall" />
            <Skeleton className="w-131 h-23 rounded-xsmall" />
          </div>
        </div>
        <div className="flex flex-col gap-10">
          <Skeleton className="w-98 h-23 rounded-xsmall" />
          <Skeleton className="w-98 h-23 rounded-xsmall" />
        </div>
      </div>
    );

  return (
    <article
      className={cn(
        'w-234 min-h-268 flex flex-col py-16 px-14 justify-between bg-Gray-white rounded-medium',
        props.className,
      )}
      onClick={(e) => props.onClick?.(props.idIdx, e)}
    >
      <section className="flex flex-col gap-12">
        <LazyImage
          alt={props.name}
          className="size-52 rounded-mlarge border border-Gray-300"
          src={props.imageUrl}
        />
        <p className="typo-body3 text-Gray-black font-500 line-clamp-2 break-all text-ellipsis w-186">
          {props.name}
        </p>
      </section>
      <section className="flex flex-col gap-10">
        <section className="flex gap-6">
          {props.dday ? (
            <Badge className="text-R-400">{props.dday}</Badge>
          ) : null}
          {props.provisionList.map((provision) => (
            <Badge key={provision} className="text-G-600">
              {provision}
            </Badge>
          ))}
          {props.provisionProcess ? (
            <Badge className="text-Gray-700">{props.provisionProcess}</Badge>
          ) : null}
        </section>
        <section className="flex flex-col">
          <p className="typo-caption1 font-400 text-Gray-600 w-173 line-clamp-1 text-ellipsis">
            {props.benefit}
          </p>
          <section className="flex justify-between">
            <p className="typo-caption1 font-400 text-Gray-600">
              {props.endAt}
            </p>
            <Button
              className="w-fit h-fit p-0"
              theme="sub-text"
              onClick={(e) => {
                e.stopPropagation();
                props.onClickLike?.(props.id, props.isLike);
              }}
            >
              {props.isLike ? (
                <SvgLikeFill className="size-16 text-B-500" name="like" />
              ) : (
                <SvgLike className="size-16 text-Gray-600" name="not-like" />
              )}
            </Button>
          </section>
        </section>
      </section>
    </article>
  );
};
