'use client';

import type { PolicyVerticalCardProps } from '../../PolicyVerticalCard';
import type { SwiperProps } from 'swiper/react';

import { useQuery } from '@tanstack/react-query';
import { Mousewheel } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { LIKE_TYPE } from '@/api';
import { Skeleton } from '@/components/server';
import { ROUTES } from '@/constants';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { useLike } from '@/hooks/useLike';
import { policyQueryOptions } from '@/query-factory';

import { PolicyVerticalCard } from '../../PolicyVerticalCard';

import styles from './SimilarPolicy.module.scss';

export const SimilarPolicy = () => {
  const router = useCustomRouter();

  const { data: convertSimilarPolicyList, isLoading } = useQuery({
    ...policyQueryOptions.recentSimilar(),
    select: (data) =>
      data.context?.policy_list?.map(
        (policy) =>
          ({
            id: policy.id,
            idIdx: policy.meta_policy_id_idx,
            imageUrl: policy.agency_logo,
            provisionList: policy.provision_type_str_list,
            name: policy.policy_name,
            isLike: policy.like_yn,
            endAt: policy.expiration_date,
            dday: policy.dday,
            benefit: policy.support_benefit,
            provisionProcess: policy.provision_process_str,
          }) satisfies PolicyVerticalCardProps,
      ),
  });

  const { like } = useLike();

  const handleClickCard = (idIdx: string) => {
    const pathname = ROUTES.POLICY_DETAIL.pathname({
      idIdx: idIdx,
    });

    router.push(pathname);
  };

  const hasNoList =
    !isLoading &&
    convertSimilarPolicyList &&
    convertSimilarPolicyList.length < 1;

  if (hasNoList) return null;

  if (isLoading)
    return (
      <div className="px-20">
        <Skeleton className="w-188 h-30 rounded-xsmall my-10" />
        <Swiper {...SWIPER_PROPS}>
          {Array.from({ length: SKELETON_CARD_COUNT }, (_, index) => (
            <SwiperSlide key={index} className="w-fit">
              <PolicyVerticalCard isLoading />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );

  return (
    <article>
      <h2 className="typo-body1 font-600 text-Gray-black py-10 pl-20">
        내가 본 정책과 비슷해요
      </h2>
      <Swiper {...SWIPER_PROPS} className={styles['card-list']}>
        {convertSimilarPolicyList?.map((policy) => (
          <SwiperSlide key={policy.id} className="w-fit last:mr-20">
            <PolicyVerticalCard
              {...policy}
              onClick={() => {
                handleClickCard(policy.idIdx);
              }}
              onClickLike={(id, isLike) => {
                like({
                  contentId: id,
                  isLike,
                  likeType: LIKE_TYPE.POLICY,
                });
              }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </article>
  );
};

const SWIPER_PROPS = {
  grabCursor: true,
  spaceBetween: 8,
  slidesPerView: 'auto',
  modules: [Mousewheel],
} satisfies SwiperProps;

const SKELETON_CARD_COUNT = 4;
