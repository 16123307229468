import type { COMMUNITY_POST_SORT_TYPE } from '@/api';
import type { CommunityKeys } from '@/constants';

import { createUuid } from '@common/utils';
import {
  infiniteQueryOptions,
  keepPreviousData,
  queryOptions,
} from '@tanstack/react-query';

import { AUTH_API, UNAUTH_API } from '@/api';
import { queryKeys } from '@/constants/query-keys';

export const communityQueryOptionsKeys = {
  welloNews: createUuid(),
  policyTalk: createUuid(),
  freeTalk: createUuid(),
  subCategory: createUuid(),
  latest: createUuid(),
  communityFreeTalkHot: createUuid(),
  communityPolicyTalkHot: createUuid(),
  myCommunityPostedTypeList: createUuid(),
  myCommunityPostedList: createUuid(),
  policyTalkDetail: createUuid(),
  rollingBannerList: createUuid(),
  newPolicyInfo: createUuid(),
  popularPolicyTalk: createUuid(),
  popularHometownNews: createUuid(),
  popularPolicyInfo: createUuid(),
  popularFreeTalk: createUuid(),
};

export const communityFeedbackQueryOptionsKeys = {
  welloNews: createUuid(),
  policyTalk: createUuid(),
  freeTalk: createUuid(),
};

interface CommunityQueryOptionsNewsProps {
  searchKeyword?: string;
  isLogin?: boolean;
}

interface CommunityQueryOptionsLatestProps {
  isLogin: boolean | undefined;
}

interface CommunityQueryOptionsPolicyTalkProps {
  searchKeyword?: string;
  isLogin?: boolean;
  code?: string;
}

interface CommunityQueryOptionsDetailProps {
  idIdx: string;
  isLogin?: boolean;
  category: Exclude<CommunityKeys, 'hometownNews' | 'policyInfo'>;
}

interface CommunityQueryOptionsFeedbackProps {
  idIdx: string;
  isLogin: boolean | undefined;
  category: Exclude<CommunityKeys, 'hometownNews' | 'policyInfo'>;
}

interface CommunityQueryOptionsMyCommunityPostedTypeListProps {
  isLogin: boolean | undefined;
}

interface CommunityQueryOptionsMyCommunityPostedListProps {
  isLogin: boolean | undefined;
  communitySort: COMMUNITY_POST_SORT_TYPE;
  communityType: string;
}

export const communityQueryOptions = {
  welloNews: ({ searchKeyword, isLogin }: CommunityQueryOptionsNewsProps) => {
    const INITIAL_PAGE = 1;
    const INITIAL_SIZE = 20;

    return infiniteQueryOptions({
      queryKey: [
        communityQueryOptionsKeys.welloNews,
        queryKeys.like,
        searchKeyword,
        isLogin,
        INITIAL_PAGE,
        INITIAL_SIZE,
      ],
      enabled: isLogin !== undefined,
      initialPageParam: INITIAL_PAGE,
      queryFn: ({ pageParam = INITIAL_PAGE }) => {
        const params = { page: pageParam, size: INITIAL_SIZE, searchKeyword };

        return isLogin
          ? AUTH_API.getCommunityNewsList(params)
          : UNAUTH_API.getCommunityNewsList(params);
      },
      getNextPageParam: (lastPage) => {
        const { page: currentPage, total_count: totalCount } =
          lastPage.context ?? {};

        if (!totalCount || !currentPage) return;

        const nextPage = currentPage + 1;
        const totalPageCount = Math.ceil(totalCount / INITIAL_SIZE);

        if (nextPage <= totalPageCount) return nextPage;
      },
    });
  },
  policyTalk: ({
    isLogin,
    code,
    searchKeyword,
  }: CommunityQueryOptionsPolicyTalkProps) => {
    const INITIAL_PAGE = 1;
    const INITIAL_SIZE = 20;

    return infiniteQueryOptions({
      queryKey: [
        communityQueryOptionsKeys.policyTalk,
        queryKeys.bookmark,
        INITIAL_PAGE,
        INITIAL_SIZE,
        code,
        searchKeyword,
        isLogin,
      ],
      enabled: isLogin !== undefined,
      queryFn: ({ pageParam = INITIAL_PAGE }) => {
        const params = {
          page: pageParam,
          size: INITIAL_SIZE,
          code,
          searchKeyword,
        };

        return isLogin
          ? AUTH_API.getCommunityPolicyTalkList(params)
          : UNAUTH_API.getCommunityPolicyTalkList(params);
      },
      placeholderData: keepPreviousData,
      getNextPageParam: (lastPage) => {
        const { page: currentPage, total_count: totalCount } =
          lastPage.context ?? {};

        if (!totalCount || !currentPage) return;

        const nextPage = currentPage + 1;
        const totalPageCount = Math.ceil(totalCount / INITIAL_SIZE);

        if (nextPage <= totalPageCount) return nextPage;
      },
      initialPageParam: INITIAL_PAGE,
    });
  },
  subCategoryList: ({ communityTypeCd }: { communityTypeCd: string }) => {
    return queryOptions({
      queryKey: [communityQueryOptionsKeys.subCategory, communityTypeCd],
      queryFn: () =>
        UNAUTH_API.getCommunitySubCategoryList({ communityTypeCd }),
    });
  },
  freeTalk: ({ isLogin, searchKeyword }: CommunityQueryOptionsNewsProps) => {
    const INITIAL_PAGE = 1;
    const INITIAL_SIZE = 20;

    return infiniteQueryOptions({
      queryKey: [
        communityQueryOptionsKeys.freeTalk,
        queryKeys.like,
        INITIAL_PAGE,
        INITIAL_SIZE,
        isLogin,
        searchKeyword,
      ],
      queryFn: ({ pageParam = INITIAL_PAGE }) => {
        const params = {
          page: pageParam,
          size: INITIAL_SIZE,
          searchKeyword,
        };

        return isLogin
          ? AUTH_API.getCommunityFreeTalkList(params)
          : UNAUTH_API.getCommunityFreeTalkList(params);
      },
      getNextPageParam: (lastPage) => {
        const { page: currentPage, total_count: totalCount } =
          lastPage.context ?? {};

        if (!totalCount || !currentPage) return;

        const nextPage = currentPage + 1;
        const totalPageCount = Math.ceil(totalCount / INITIAL_SIZE);

        if (nextPage <= totalPageCount) return nextPage;
      },
      initialPageParam: INITIAL_PAGE,
    });
  },
  //! 지워야됨
  detail: ({ idIdx, isLogin, category }: CommunityQueryOptionsDetailProps) =>
    queryOptions({
      queryKey: [communityQueryOptionsKeys[category], idIdx, category, isLogin],
      enabled: !!(idIdx && idIdx !== 'new') && isLogin !== undefined,
      retry: false,
      queryFn: () => {
        switch (category) {
          case 'welloNews':
            return isLogin
              ? AUTH_API.getCommunityNewsDetail({ communityIdIdx: idIdx })
              : UNAUTH_API.getCommunityNewsDetail({ communityIdIdx: idIdx });
          case 'freeTalk':
            return isLogin
              ? AUTH_API.getCommunityFreeTalkDetail({ communityIdIdx: idIdx })
              : UNAUTH_API.getCommunityFreeTalkDetail({
                  communityIdIdx: idIdx,
                });
          case 'policyTalk':
            return isLogin
              ? AUTH_API.getCommunityPolicyTalkDetail({ communityIdIdx: idIdx })
              : UNAUTH_API.getCommunityPolicyTalkDetail({
                  communityIdIdx: idIdx,
                });
          default:
            throw new Error(`Unknown type: ${category}`);
        }
      },
    }),
  policyTalkDetail: (idx: string) => {
    return queryOptions({
      queryKey: [communityQueryOptionsKeys.policyTalkDetail, idx],
      queryFn: () =>
        AUTH_API.getCommunityPolicyTalkDetail({ communityIdIdx: idx }),
    });
  },
  feedback: ({
    idIdx,
    isLogin,
    category,
  }: CommunityQueryOptionsFeedbackProps) =>
    queryOptions({
      queryKey: [
        communityFeedbackQueryOptionsKeys[category],
        queryKeys.like,
        queryKeys.bookmark,
        idIdx,
        category,
        isLogin,
      ],
      staleTime: 0,
      enabled: !!(idIdx && idIdx !== 'new') && isLogin !== undefined,
      queryFn: () => {
        switch (category) {
          case 'policyTalk':
            return isLogin
              ? AUTH_API.getCommunityPolicyTalkFeedback({
                  communityIdIdx: idIdx,
                })
              : UNAUTH_API.getCommunityPolicyTalkFeedback({
                  communityIdIdx: idIdx,
                });
          case 'welloNews':
            return isLogin
              ? AUTH_API.getCommunityWelloNewsFeedback({
                  communityIdIdx: idIdx,
                })
              : UNAUTH_API.getCommunityWelloNewsFeedback({
                  communityIdIdx: idIdx,
                });
          case 'freeTalk':
            return isLogin
              ? AUTH_API.getCommunityFreeTalkFeedback({
                  communityIdIdx: idIdx,
                })
              : UNAUTH_API.getCommunityFreeTalkFeedback({
                  communityIdIdx: idIdx,
                });
          default:
            throw new Error(`Unknown type: ${category}`);
        }
      },
    }),
  policyTalkPickButton: ({
    isLogin,
    idIdx,
  }: Pick<CommunityQueryOptionsFeedbackProps, 'idIdx' | 'isLogin'>) =>
    queryOptions({
      ...communityQueryOptions.feedback({
        idIdx,
        category: 'policyTalk',
        isLogin,
      }),
      select: ({ context }) =>
        !!(
          context &&
          'comment_pick_check_yn' in context &&
          context.comment_pick_check_yn
        ),
    }),
  latest: ({ isLogin }: CommunityQueryOptionsLatestProps) =>
    queryOptions({
      queryKey: [communityQueryOptionsKeys.latest, queryKeys.like, isLogin],
      enabled: isLogin !== undefined,
      queryFn: () => {
        return isLogin
          ? AUTH_API.getLatestCommunityList()
          : UNAUTH_API.getLatestCommunityList();
      },
      select: (data) => data?.context?.contents,
    }),
  communityFreeTalkHot: ({
    page,
    size,
    enabled,
  }: {
    page: number;
    size: number;
    enabled: boolean;
  }) =>
    queryOptions({
      queryKey: [
        communityQueryOptionsKeys.communityFreeTalkHot,
        queryKeys.like,
        page,
        size,
      ],
      queryFn: () => UNAUTH_API.getCommunityFreeTalkHot({ page, size }),
      enabled,
    }),
  communityPolicyTalkHot: ({
    page,
    size,
    enabled,
  }: {
    page: number;
    size: number;
    enabled: boolean;
  }) =>
    queryOptions({
      queryKey: [
        communityQueryOptionsKeys.communityPolicyTalkHot,
        queryKeys.like,
        page,
        size,
      ],
      queryFn: () => UNAUTH_API.getCommunityPolicyTalkHot({ page, size }),
      enabled,
    }),
  myCommunityPostedTypeList: ({
    isLogin,
  }: CommunityQueryOptionsMyCommunityPostedTypeListProps) =>
    queryOptions({
      queryKey: [communityQueryOptionsKeys.myCommunityPostedTypeList, isLogin],
      enabled: isLogin !== undefined,
      queryFn: AUTH_API.getMyCommunityTypeListUsingGet,
    }),
  myCommunityPostedList: ({
    isLogin,
    communitySort,
    communityType,
  }: CommunityQueryOptionsMyCommunityPostedListProps) => {
    const INITIAL_PAGE = 1;
    const INITIAL_SIZE = 20;

    return infiniteQueryOptions({
      queryKey: [
        communityQueryOptionsKeys.myCommunityPostedList,
        INITIAL_PAGE,
        INITIAL_SIZE,
        communitySort,
        communityType,
        queryKeys.like,
      ],
      enabled: isLogin !== undefined && !!communityType,
      queryFn: ({ pageParam = INITIAL_PAGE }) => {
        const params = {
          page: pageParam,
          size: INITIAL_SIZE,
          communitySort,
          communityType,
        };

        return AUTH_API.getMyCommunityList(params);
      },
      getNextPageParam: (lastPage) => {
        const { page: currentPage, total_count: totalCount } =
          lastPage.context ?? {};

        if (!totalCount || !currentPage) return;

        const nextPage = currentPage + 1;
        const totalPageCount = Math.ceil(totalCount / INITIAL_SIZE);

        if (nextPage <= totalPageCount) return nextPage;
      },
      initialData: undefined,
      initialPageParam: INITIAL_PAGE,
      placeholderData: keepPreviousData,
    });
  },
  rollingBannerList: () =>
    queryOptions({
      queryKey: [communityQueryOptionsKeys.rollingBannerList],
      queryFn: UNAUTH_API.getRollingBannerList,
    }),
  newPolicyInfo: () =>
    queryOptions({
      queryKey: [communityQueryOptionsKeys.newPolicyInfo],
      queryFn: UNAUTH_API.getNewPolicyInfo,
    }),
  popularPolicyTalk: () =>
    queryOptions({
      queryKey: [communityQueryOptionsKeys.popularPolicyTalk],
      queryFn: UNAUTH_API.getPolicyTalkPopular,
    }),
  popularHometownNews: () =>
    queryOptions({
      queryKey: [communityQueryOptionsKeys.popularHometownNews],
      queryFn: UNAUTH_API.getCommunityHometownNewsPopular,
    }),
  popularPolicyInfo: () =>
    queryOptions({
      queryKey: [communityQueryOptionsKeys.popularPolicyInfo],
      queryFn: UNAUTH_API.getPolicyInfoPopular,
    }),
  popularFreeTalk: () =>
    queryOptions({
      queryKey: [communityQueryOptionsKeys.popularFreeTalk],
      queryFn: UNAUTH_API.getFreeTalkPopular,
    }),
};
