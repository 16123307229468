import type { QueryKey } from '@tanstack/react-query';

import { useMemo } from 'react';

import { hasObjectKey } from '@common/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { cloneDeep, debounce } from 'es-toolkit';

import { AUTH_API } from '@/api';
import { ROUTES } from '@/constants';
import { queryKeys } from '@/constants/query-keys';
import { MESSAGE_KEYWORD, useHandleApp } from '@/modules/wello-app';
import { myInfoQueryOptionsKeys } from '@/query-factory/my-info';
import { useAuthStore } from '@/stores/AuthStore';

import { useCustomRouter } from './useCustomRouter';

interface LikeParams {
  /**
   * 쿼리키 조회용
   */
  idx?: string;
  contentId: number;
  isLike: boolean;
  likeType: string;
}

export const useLike = () => {
  const { mutate } = useMutation({
    mutationFn: ({ contentId, isLike, likeType }: LikeParams) => {
      const requestBody = {
        requestBody: {
          content_id: contentId,
          like_type_cd: likeType,
        },
      };

      return isLike
        ? AUTH_API.deleteLike(requestBody)
        : AUTH_API.postLike(requestBody);
    },
    meta: {
      reset: [
        [myInfoQueryOptionsKeys.listLikeContentsType],
        [myInfoQueryOptionsKeys.listLikeContents],
      ],
    },
  });

  const queryClient = useQueryClient();

  const router = useCustomRouter();

  const { request: requestHaptic } = useHandleApp({
    type: MESSAGE_KEYWORD.HAPTIC,
  });

  const like = useMemo(() => {
    const backupMap = new Map<QueryKey, unknown>();

    const originLikeMap: Map<number, boolean> = new Map();

    const debouncedMutateMap: Map<
      number,
      ReturnType<typeof debounce>
    > = new Map([]);

    return (params: LikeParams) => {
      requestHaptic();
      const { contentId, isLike, idx } = params;

      const { isLogin } = useAuthStore.getState();

      if (!isLogin)
        return router.push(
          ROUTES.LOGIN.withSearchParams({
            searchParams: {
              redirect: location.href,
            },
          }),
        );

      if (!originLikeMap.has(contentId)) {
        originLikeMap.set(contentId, isLike);
      }

      const likeQueries = queryClient.getQueriesData({
        predicate: (query) => query.queryKey.includes(queryKeys.like),
      });

      likeQueries.forEach(([key, data]) => {
        if (
          (key.includes(contentId) || (idx && key.includes(idx))) &&
          hasObjectKey(data, 'context') &&
          hasObjectKey(data.context, 'like_count') &&
          hasObjectKey(data.context, 'like_yn')
        ) {
          const newData = cloneDeep(data);

          if (
            hasObjectKey(newData.context, 'like_count') &&
            typeof newData.context.like_count === 'number' &&
            hasObjectKey(newData.context, 'like_yn') &&
            typeof newData.context.like_yn === 'boolean'
          ) {
            newData.context.like_count = isLike
              ? newData.context.like_count - 1
              : newData.context.like_count + 1;

            newData.context.like_yn = !isLike;

            queryClient.setQueryData(key, newData);
          }
        } else
          for (const idKey of [
            'community_id',
            'best_contents_id',
            'hometown_news_id',
            'wello_comment_id',
            'meta_policy_id',
            'id',
          ] as const) {
            const newData = cloneDeep(data);

            const findTargetObject = (obj: unknown) => {
              if (obj && typeof obj === 'object') {
                if (hasObjectKey(obj, idKey) && obj[idKey] === contentId) {
                  switch (idKey) {
                    case 'id': {
                      if ('community_type_string' in obj) return obj;
                      if ('meta_policy_id_idx' in obj) return obj;
                      if ('id_idx' in obj) return obj;
                      break;
                    }

                    default:
                      return obj;
                  }
                }

                for (const key in obj) {
                  if (hasObjectKey(obj, key)) {
                    const targetObject: unknown = findTargetObject(obj[key]);

                    if (targetObject) {
                      return targetObject;
                    }
                  }
                }
              }
            };

            const targetObject = findTargetObject(newData);

            if (targetObject) {
              const changeLikeCount = (obj: unknown) => {
                if (obj && typeof obj === 'object') {
                  if (
                    hasObjectKey(obj, 'like_count') &&
                    typeof obj.like_count === 'number'
                  ) {
                    obj['like_count'] = isLike
                      ? obj['like_count'] - 1
                      : obj['like_count'] + 1;
                  }
                  for (const key in obj) {
                    if (hasObjectKey(obj, key)) {
                      changeLikeCount(obj[key]);
                    }
                  }
                }
              };

              changeLikeCount(targetObject);

              const changeLikeYn = (obj: unknown) => {
                if (obj && typeof obj === 'object') {
                  if (
                    hasObjectKey(obj, 'like_yn') &&
                    typeof obj.like_yn === 'boolean'
                  ) {
                    obj['like_yn'] = !isLike;
                  }
                  for (const key in obj) {
                    if (hasObjectKey(obj, key)) {
                      changeLikeYn(obj[key]);
                    }
                  }
                }
              };

              changeLikeYn(targetObject);

              if (!backupMap.has(key)) {
                backupMap.set(key, data);
              }

              queryClient.setQueryData(key, newData);
            }
          }
      });

      if (!debouncedMutateMap.has(contentId)) {
        debouncedMutateMap.set(
          contentId,
          debounce<typeof mutate>((params, options) => {
            if (originLikeMap.get(contentId) === !params.isLike) return;

            return mutate(params, options);
          }, 500),
        );
      }

      debouncedMutateMap.get(contentId)?.(params, {
        onError: () => {
          originLikeMap.delete(contentId);
          backupMap.forEach((data, key) => {
            queryClient.setQueryData(key, data);
          });
        },
        onSuccess: () => {
          backupMap.clear();

          originLikeMap.set(contentId, !params.isLike);
        },
      });
    };
  }, [mutate, queryClient, requestHaptic, router]);

  return { like };
};
