import { useState } from 'react';

import { Button, Modal, Skeleton } from '@common/components';
import { useSuspenseQuery } from '@tanstack/react-query';

import { CustomLink } from '@/components/atom/CustomLink';
import { LazyImage } from '@/components/client';
import { ROUTES } from '@/constants';
import { policyQueryOptions } from '@/query-factory';

export const PolicyPickSkeleton = () => (
  <div className="p-20 rounded-large flex flex-col">
    <div className="flex items-center justify-between mb-108">
      <div>
        <Skeleton className="h-15 w-45 mb-9" />
        <Skeleton className="h-25 w-200 mb-4" />
        <Skeleton className="h-25 w-150" />
      </div>
      <Skeleton className="size-46" />
    </div>
    <div className="h-89 rounded-medium px-16 py-12 flex items-center gap-12">
      <Skeleton className="size-46 rounded-large" />
      <div className="flex flex-col gap-2">
        <Skeleton className="h-18 w-45" />
        <Skeleton className="h-25 w-200" />
      </div>
    </div>
  </div>
);

interface PolicyPickCardProps {
  idx: string;
  interestTypeList: string;
  title: string;
  image: string;
  agencyLogo: string;
  policyName: string;
  description: string;
  backgroundColor: string;
}

export const PolicyPickCard = ({
  idx,
  interestTypeList,
  title,
  image,
  agencyLogo,
  policyName,
  description,
  backgroundColor,
}: PolicyPickCardProps) => {
  const { data } = useSuspenseQuery(policyQueryOptions.policyDetail(idx));
  const [opened, setOpened] = useState(false);

  if (!data.aiKeywordList || !data.aiKeywordList.length) {
    return null;
  }

  return (
    <>
      <article
        className="p-20 rounded-large cursor-pointer"
        style={{ backgroundColor }}
        onClick={(e) => {
          e.stopPropagation();
          setOpened(true);
        }}
      >
        <header className="flex items-center justify-between">
          <div>
            <span className="typo-caption1 font-600 text-B-500">
              {interestTypeList}
            </span>
            <h2 className="typo-body2 font-700 text-Gray-900 whitespace-pre-line">
              {title}
            </h2>
          </div>
          <img
            alt="w-medal"
            className="size-46 object-cover"
            src="/assets/pngs/home/medal.png"
          />
        </header>
        <LazyImage
          alt={title}
          className="size-124 object-contain m-auto mt-12 mb-20 p-4"
          src={image}
        />
        <footer className="flex items-center gap-12 rounded-medium bg-Gray-white bg-opacity-60 px-12 py-16">
          <LazyImage
            alt="policy-logo"
            className="size-46 object-contain rounded-large border-1 border-Gray-200"
            src={agencyLogo}
          />
          <div className="flex flex-col gap-2">
            <h3 className="typo-body4 font-600">{policyName}</h3>
            <p className="typo-body4 font-400 text-Gray-800">{description}</p>
          </div>
        </footer>
      </article>
      <Modal
        className="px-20 py-24"
        opened={opened}
        onClose={() => setOpened(false)}
      >
        <article className="max-w-[400px] text-start mb-28">
          <header className="flex items-center gap-12 mb-12">
            <LazyImage
              alt="policy-logo"
              className="size-36 rounded-large border-1 border-Gray-200"
              src={agencyLogo}
            />
            <h2 className="text-Gray-900">{policyName}</h2>
          </header>
          <section className="bg-Gray-200 p-16 rounded-medium">
            <ul className="typo-body4 text-Gray-900 flex-col gap-8 flex mb-18 ml-16">
              {data.aiSummaryList?.map((summary, index) => (
                <li
                  key={index}
                  className="list-disc marker:text-[0.6rem] animate-delay-75 break-keep whitespace-pre-wrap"
                >
                  {summary}
                </li>
              ))}
            </ul>
            <ul className="flex flex-wrap gap-4">
              {data.aiKeywordList.map((keyword, index) => (
                <li
                  key={index}
                  className="typo-caption1 text-Gray-700 font-600 px-8 py-2 bg-Gray-white rounded-pill"
                >
                  #{keyword}
                </li>
              ))}
            </ul>
          </section>
        </article>
        <Button asChild dimensions="H48">
          <CustomLink
            href={ROUTES.POLICY_DETAIL.pathname({
              idIdx: idx,
            })}
          >
            더 보러가기
          </CustomLink>
        </Button>
      </Modal>
    </>
  );
};
