'use client';

import { Fragment, useState } from 'react';

import { SvgChevronright } from '@common/icon';
import { cn } from '@common/utils';
import { useQuery } from '@tanstack/react-query';
import Link from 'next/link';
import { Mousewheel } from 'swiper/modules';
import { Swiper, SwiperSlide, type SwiperProps } from 'swiper/react';

import { ROUTES } from '@/constants';
import { communityQueryOptions } from '@/query-factory/community';

import { PolicyReviewCard } from '../../PolicyReviewCard';

const CARD_COUNT = 3;

const SWIPER_PROPS = {
  slidesPerView: 'auto',
  spaceBetween: 8,
  mousewheel: true,
  modules: [Mousewheel],
} satisfies SwiperProps;

const SIZE = 10;
const PAGE = 1;

const TALK_TABS = [
  { type: 'POLICY', label: '정책수다' },
  { type: 'FREE', label: '자유수다' },
];

export const HotTalkSection = () => {
  const [talkType, setTalkType] =
    useState<(typeof TALK_TABS)[number]['type']>('POLICY');

  const freeTalkData = useQuery(
    communityQueryOptions.communityFreeTalkHot({
      enabled: talkType === 'FREE',
      page: PAGE,
      size: SIZE,
    }),
  );

  const policyTalkData = useQuery(
    communityQueryOptions.communityPolicyTalkHot({
      enabled: talkType === 'POLICY',
      page: PAGE,
      size: SIZE,
    }),
  );

  const data =
    talkType === 'FREE'
      ? freeTalkData?.data?.context?.contents
      : policyTalkData?.data?.context?.contents;

  const isLoading =
    talkType === 'FREE' ? freeTalkData.isLoading : policyTalkData.isLoading;

  return (
    <section>
      <header className="py-10 px-20 flex justify-between items-center">
        <Link
          href={
            talkType === 'POLICY'
              ? ROUTES.COMMUNITY_CATEGORY.pathname({
                  category: 'policyTalk',
                })
              : ROUTES.COMMUNITY_CATEGORY.pathname({
                  category: 'freeTalk',
                })
          }
        >
          <h2 className="typo-body2 font-600 flex items-center gap-4">
            <img
              alt="HOT한 수다"
              className="size-20"
              src="/assets/pngs/home/fire.png"
            />
            HOT한 수다
            <SvgChevronright className="size-20 text-Gray-500" />
          </h2>
        </Link>
        <div className="flex gap-4">
          {TALK_TABS.map(({ type, label }, index) => (
            <Fragment key={type}>
              <button
                className={cn('px-4 py-2 transition-all font-600 typo-body4 ', {
                  'text-Gray-600': talkType !== type,
                })}
                onClick={() => setTalkType(type)}
              >
                {label}
              </button>
              {index < TALK_TABS.length - 1 && (
                <div className="w-1 h-14 bg-Gray-400 self-center" />
              )}
            </Fragment>
          ))}
        </div>
      </header>
      <Swiper {...SWIPER_PROPS} className="flex gap-10 px-20">
        {!data ? (
          Array.from({ length: CARD_COUNT }, (_, index) => (
            <SwiperSlide key={index} className="w-fit">
              <PolicyReviewCard isLoading />
            </SwiperSlide>
          ))
        ) : data.length ? (
          data.map((talk) => {
            return (
              <SwiperSlide key={talk.community_id_idx} className="w-fit">
                <PolicyReviewCard
                  commentCount={talk.comment_count}
                  content={talk.summary}
                  isLoading={isLoading}
                  likeCount={talk.like_count}
                  link={
                    talkType === 'POLICY'
                      ? ROUTES.POLICY_TALK_DETAIL.pathname({
                          idIdx: talk.community_id_idx,
                        })
                      : ROUTES.FREE_TALK_DETAIL.pathname({
                          idIdx: talk.community_id_idx,
                        })
                  }
                  readCount={talk.reading_count}
                />
              </SwiperSlide>
            );
          })
        ) : (
          <SwiperSlide className="w-full flex-center h-183 typo-body4 font-400 text-Gray-600">
            해당 정책과 관련된 아티클이 없어요.
          </SwiperSlide>
        )}
      </Swiper>
    </section>
  );
};
