'use client';

import type { SwiperProps } from 'swiper/react';

import { Chip } from '@common/components';
import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'next/navigation';
import { Mousewheel } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { BANNER_CONTENTS_TYPE, LIKE_TYPE } from '@/api';
import { CustomLink } from '@/components/atom/CustomLink';
import { PolicyCard } from '@/components/molecule/PolicyCard';
import { Skeleton } from '@/components/server';
import { ROUTES } from '@/constants';
import { useLike } from '@/hooks/useLike';
import { policyQueryOptions } from '@/query-factory';

import { MainBannerSwiper2 } from '../../MainBannerSwiper2';

import styles from './InterestedFieldPolicy.module.scss';

export const InterestedFieldPolicy = () => {
  const searchParams = useSearchParams();
  const selectedProvisionCode =
    searchParams?.get(SELECTED_PROVISION_CODE_QUERY_STRING_KEY) ?? undefined;

  const interestPolicyListQueryOption = policyQueryOptions.interest({
    provisionType: selectedProvisionCode,
  });

  const { like } = useLike();

  const { data, isLoading } = useQuery(interestPolicyListQueryOption);

  const handleClickProvisionTab = (provisionCode: string) => {
    const queryString = getQueryStringByProvisionCode(provisionCode);

    const replaceUrl = `${window.location.pathname}?${queryString}`;
    window.history.replaceState({ path: replaceUrl }, '', replaceUrl);
  };

  const getQueryStringByProvisionCode = (provisionCode: string) => {
    const params = new URLSearchParams(searchParams?.toString());
    params.set(SELECTED_PROVISION_CODE_QUERY_STRING_KEY, provisionCode);

    return params.toString();
  };

  const provisionTabList = data?.context?.provision_type_list;
  const policyList = data?.context?.policy_list?.map((policy) => ({
    id: policy.id,
    idIdx: policy.meta_policy_id_idx,
    imageUrl: policy.agency_logo,
    name: policy.policy_name,
    provisionList: policy.provision_type_str_list,
    benefit: policy.support_benefit,
    endAt: policy.expiration_date,
    isLike: policy.like_yn,
    dday: policy.dday,
    provisionProcess: policy.provision_process_str,
    likeCount: policy.like_count,
  }));

  const hasNoList = !isLoading && policyList && policyList.length < 1;

  if (hasNoList) return null;

  return (
    <article>
      <h2 className="typo-body1 font-600 text-Gray-black py-10 mb-8 pl-20">
        관심 분야별 맞춤 정책
      </h2>
      {!isLoading ? (
        <Swiper {...SWIPER_PROPS} className={styles['provision-list']}>
          {provisionTabList?.map((provisionTab) => (
            <SwiperSlide key={provisionTab.code} className="w-fit last:mr-20">
              <Chip
                checked={
                  data?.context?.selected_code_provision_type ===
                  provisionTab.code
                }
                className="flex items-center typo-body4 h-34 select-none"
                theme="sub"
                onClick={() => {
                  handleClickProvisionTab(provisionTab.code);
                }}
              >
                {provisionTab.value}
              </Chip>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <Skeleton className="w-1/2 h-34 rounded-xsmall ml-20" />
      )}
      <MainBannerSwiper2
        bannerType={BANNER_CONTENTS_TYPE.HOME_LOWER}
        className="mt-20"
      />
      {!isLoading ? (
        <ul className="flex flex-col gap-8 mt-12 pr-20 pl-20">
          {policyList?.map((policy) => (
            <li key={policy.id}>
              <CustomLink
                href={ROUTES.POLICY_DETAIL.pathname({
                  idIdx: policy.idIdx,
                })}
              >
                <PolicyCard
                  {...policy}
                  likeCount={policy.likeCount}
                  onClickLike={(id, isLike, e) => {
                    e.preventDefault();
                    like({
                      contentId: id,
                      isLike,
                      likeType: LIKE_TYPE.POLICY,
                    });
                  }}
                />
              </CustomLink>
            </li>
          ))}
        </ul>
      ) : (
        <ul className="flex flex-col gap-8 pt-20 pr-20 ml-20">
          {Array.from({ length: SKELETON_CARD_COUNT }, (_, index) => (
            <PolicyCard key={index} isLoading />
          ))}
        </ul>
      )}
    </article>
  );
};

const SWIPER_PROPS = {
  grabCursor: true,
  spaceBetween: 6,
  slidesPerView: 'auto',
  modules: [Mousewheel],
} satisfies SwiperProps;

const SELECTED_PROVISION_CODE_QUERY_STRING_KEY = 'selectedProvisionCode';
const SKELETON_CARD_COUNT = 3;
