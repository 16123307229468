export const NewIcon = () => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="7.99817" cy="8" fill="#F5511D" r="8" />
    <path
      d="M10.3688 5V11H9.40745L6.56491 6.89779H6.51518V11H5.43784V5H6.41574L9.2417 9.10221H9.29971V5H10.3688Z"
      fill="white"
    />
  </svg>
);
