'use client';

import { Suspense } from 'react';

import { Skeleton } from '@common/components';
import { useSuspenseQuery } from '@tanstack/react-query';

import { policyQueryOptions } from '@/query-factory';

import { PolicyPickCard, PolicyPickSkeleton } from './PolicyPickCard';

const PolicyPickCardsSectionContent = () => {
  const { data } = useSuspenseQuery(policyQueryOptions.policyPick());

  return (
    <section className="flex flex-col gap-16 px-20 pt-10">
      <h2 className="typo-body2 font-600">웰로픽! 요즘 뜨는 정책</h2>
      {data?.map((item) => (
        <Suspense key={item.id_idx} fallback={<PolicyPickSkeleton />}>
          <PolicyPickCard
            key={item.id_idx}
            agencyLogo={item.agency_logo}
            backgroundColor={item.background_color}
            description={item.description}
            idx={item.id_idx}
            image={item.image}
            interestTypeList={item.interest_type_list}
            policyName={item.policy_name}
            title={item.title}
          />
        </Suspense>
      ))}
    </section>
  );
};

export const PolicyPickSection = () => {
  return (
    <Suspense
      fallback={
        <div className="flex flex-col gap-16 px-20">
          <Skeleton className="h-27 w-100" />
          {Array.from({ length: 3 }).map((_, index) => (
            <PolicyPickSkeleton key={index} />
          ))}
        </div>
      }
    >
      <PolicyPickCardsSectionContent />
    </Suspense>
  );
};
