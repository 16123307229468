import type { SwiperProps } from 'swiper/react';

import { SvgChevronright } from '@common/icon';
import { Mousewheel } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { CustomLink } from '@/components/atom/CustomLink';
import { ROUTES } from '@/constants';

const POPULAR_VOUCHER_LIST = [
  {
    title: `지역사회\n서비스`,
    image: '/assets/pngs/home/popular-voucher/houses.png',
    categoryId: 19,
    subCategoryId: 6,
  },
  {
    title: `첫만남\n이용권`,
    image: '/assets/pngs/home/popular-voucher/baby.png',
    categoryId: 20,
    subCategoryId: 25,
  },
  {
    title: `아이돌봄\n서비스`,
    image: '/assets/pngs/home/popular-voucher/girl.png',
    categoryId: 20,
    subCategoryId: 17,
  },
  {
    title: `산모신생아\n건강관리`,
    image: '/assets/pngs/home/popular-voucher/breast-feeding.png',
    categoryId: 20,
    subCategoryId: 4,
  },
  {
    title: `건강보험\n임신출산`,
    image: '/assets/pngs/home/popular-voucher/pregnant-woman.png',
    categoryId: 20,
    subCategoryId: 23,
  },
  {
    title: `장애인활동\n지원`,
    image: '/assets/pngs/home/popular-voucher/manual-wheelchair.png',
    categoryId: 22,
    subCategoryId: 5,
  },
] as const;

const SWIPER_PROPS = {
  slidesPerView: 'auto',
  spaceBetween: 24,
  mousewheel: true,
  modules: [Mousewheel],
} satisfies SwiperProps;

const PopularVoucher = () => {
  return (
    <section>
      <CustomLink href={ROUTES.VOUCHER_CATEGORY.pathname({ categoryId: 0 })}>
        <h2 className="w-full typo-body2 font-600 flex justify-between items-center gap-4 px-20 py-10">
          인기 바우처 제공기관 보기
          <SvgChevronright className="size-20 text-Gray-500" />
        </h2>
      </CustomLink>
      <Swiper {...SWIPER_PROPS} className="flex gap-20 px-20">
        {POPULAR_VOUCHER_LIST.map(
          ({ title, image, categoryId, subCategoryId }) => (
            <SwiperSlide key={title} className="w-fit">
              <CustomLink
                href={`${ROUTES.VOUCHER_CATEGORY.pathname({ categoryId })}#${subCategoryId}`}
              >
                <div className="size-60 rounded-xlarge bg-Gray-white flex justify-center items-center">
                  <img alt={title} className="size-30" src={image} />
                </div>
                <p className="pt-8 font-500 text-Gray-800 text-[0.875rem] leading-[130%] whitespace-pre-wrap text-center">
                  {title}
                </p>
              </CustomLink>
            </SwiperSlide>
          ),
        )}
      </Swiper>
    </section>
  );
};

export default PopularVoucher;
