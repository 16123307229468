import { SvgChevronright, SvgVisible } from '@common/icon';
import { useQuery } from '@tanstack/react-query';
import Image from 'next/image';
import Link from 'next/link';

import { ROUTES } from '@/constants';
import { hometownNewsQueryOptions } from '@/query-factory';

export const PopularHometownNews = () => {
  const { data } = useQuery(hometownNewsQueryOptions.popularHometownNews());

  return (
    <section className="flex flex-col bg-Gray-white mx-20 rounded-mlarge">
      <header className="flex justify-between items-center p-20 pb-8">
        <h2 className="typo-body2 font-600 text-Gray-black flex items-center gap-4">
          <img
            alt="인기 동네 소식"
            className="size-20"
            src="/assets/pngs/home/sports-medal.png"
          />
          인기 동네소식
        </h2>
        <p className="typo-caption1 text-Gray-600 font-400">
          {data?.context?.standard_date_time_string} 기준
        </p>
      </header>
      <ul className="p-20 pt-0 divide-y divide-Gray-200">
        {data?.context?.contents?.map((content) => (
          <li key={content.hometown_news_id}>
            <Link
              className="flex justify-between items-center py-12"
              href={ROUTES.HOMETOWN_NEWS_DETAIL.withSearchParams({
                dynamicPath: { idIdx: content.hometown_news_id_idx },
              })}
            >
              <div className="flex items-center gap-16 pr-16">
                <div className="relative w-56 h-56 min-w-56 min-h-56 rounded-small overflow-hidden border border-Gray-200">
                  <Image
                    fill
                    alt="인기 동네 소식"
                    className="object-cover"
                    src={content.thumbnail}
                  />
                </div>
                <div>
                  <p className="typo-body3 font-500 text-Gray-black pb-4 line-clamp-2">
                    {content.hometown_news_name}
                  </p>
                  <footer className="flex gap-12 items-center typo-caption1 text-Gray-700">
                    {content.days_ago}
                    <span className="flex items-center gap-4 typo-caption1 text-B-500">
                      <SvgVisible className="size-12" />
                      {content.reading_count}명이 보는중
                    </span>
                  </footer>
                </div>
              </div>
              <SvgChevronright className="size-20 text-Gray-500" />
            </Link>
          </li>
        ))}
      </ul>
    </section>
  );
};
