'use client';

import { useQuery } from '@tanstack/react-query';

import { LIKE_TYPE } from '@/api';
import { CustomLink } from '@/components/atom/CustomLink';
import { PolicyCard } from '@/components/molecule/PolicyCard';
import { ROUTES } from '@/constants';
import { useLike } from '@/hooks/useLike';
import { policyQueryOptions } from '@/query-factory';

export const HometownPopularPolicy = () => {
  const { data, isLoading } = useQuery(policyQueryOptions.hometownPopular());

  const hometownPopularPolicyList = data?.context?.policy_list;

  const hasNoList =
    !isLoading &&
    hometownPopularPolicyList &&
    hometownPopularPolicyList.length < 1;

  const { like } = useLike();

  if (hasNoList) return null;

  return (
    <article>
      <h2 className="typo-body1 font-600 text-Gray-black py-10 flex items-center gap-4 pl-20">
        이웃이 많이 봤어요
      </h2>
      <ul className="flex flex-col gap-8 px-20">
        {hometownPopularPolicyList?.map((policy) => (
          <li key={policy.id}>
            <CustomLink
              href={ROUTES.POLICY_DETAIL.pathname({
                idIdx: policy.meta_policy_id_idx,
              })}
            >
              <PolicyCard
                benefit={policy.support_benefit}
                dday={policy.dday}
                endAt={policy.expiration_date}
                id={policy.id}
                idIdx={policy.meta_policy_id_idx}
                imageUrl={policy.agency_logo}
                isLike={policy.like_yn}
                likeCount={policy.like_count}
                name={policy.policy_name}
                provisionList={policy.provision_type_str_list}
                provisionProcess={policy.provision_process_str}
                onClickLike={(contentId, isLike) => {
                  like({
                    contentId,
                    isLike,
                    likeType: LIKE_TYPE.POLICY,
                  });
                }}
              />
            </CustomLink>
          </li>
        ))}
      </ul>
    </article>
  );
};
