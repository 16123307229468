'use client';

import type { BANNER_CONTENTS_TYPE } from '@/api';

import { cn } from '@common/utils';
import { useQuery } from '@tanstack/react-query';
import Link from 'next/link';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { LazyImage } from '@/components/client';
import { bannerQueryOptions } from '@/query-factory/banner';
import { useDeviceInfoStore } from '@/stores/DeviceStore';

import styles from './MainBannerSwiper2.module.scss';

interface MainCardSliderProps2 {
  bannerType: BANNER_CONTENTS_TYPE;
  className?: string;
}

export const MainBannerSwiper2 = ({
  bannerType,
  className,
}: MainCardSliderProps2) => {
  const viewportType = useDeviceInfoStore((state) => state.viewportType);

  const isDesktop = viewportType === 'desktop';

  const { data } = useQuery(bannerQueryOptions.bannerList({ bannerType }));

  if (!data?.context?.contents) return null;

  const filteredContents = data.context.contents.filter((content) => {
    if (isDesktop && content.title?.toLowerCase().includes('kb')) {
      return false;
    }

    return true;
  });

  return (
    <section
      className={cn('mx-20 rounded-medium overflow-hidden relative', className)}
    >
      <Swiper
        grabCursor
        loop
        autoplay={{
          delay: 5_000,
          disableOnInteraction: false,
        }}
        className={styles['swiper-wrapper']}
        modules={[Pagination, Autoplay]}
        pagination={{
          horizontalClass: styles['swiper-pagination'],
          bulletActiveClass: styles['bullet-active'],
          clickable: true,
        }}
        spaceBetween={8}
      >
        {filteredContents.map(
          ({ file_url, link_url, target_new_yn, title }, index) => {
            if (!(link_url && file_url && title))
              throw new Error('file_url, title is required');

            return (
              <SwiperSlide key={index}>
                <Link
                  aria-label={title}
                  href={link_url}
                  target={target_new_yn ? '_blank' : '_self'}
                >
                  <LazyImage alt={title} className="w-full" src={file_url} />
                </Link>
              </SwiperSlide>
            );
          },
        )}
      </Swiper>
    </section>
  );
};
