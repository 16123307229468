import type { BANNER_CONTENTS_TYPE } from '@/api';

import { createUuid } from '@common/utils';
import { queryOptions } from '@tanstack/react-query';

import { UNAUTH_API } from '@/api';

export const bannerQueryOptionsKeys = {
  banner: createUuid(),
};

export type BannerList = Awaited<ReturnType<typeof UNAUTH_API.getBannerList>>;

interface bannerListProps {
  bannerType: BANNER_CONTENTS_TYPE;
  initialData?: Awaited<ReturnType<typeof UNAUTH_API.getBannerList>>;
}

export const bannerQueryOptions = {
  bannerList: ({ bannerType }: bannerListProps) =>
    queryOptions({
      queryKey: [bannerQueryOptionsKeys.banner, bannerType],
      queryFn: () => UNAUTH_API.getBannerList(bannerType),
    }),
};
