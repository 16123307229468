import { SvgVisible } from '@common/icon';

import { CustomLink } from '@/components/atom/CustomLink';
import { Skeleton } from '@/components/server';

interface PolicyReviewCardBaseProps {
  isLoading?: boolean;
}

interface PolicyReviewCardLoadingProps extends PolicyReviewCardBaseProps {
  isLoading: true;
}

interface PolicyReviewCardLoadedProps extends PolicyReviewCardBaseProps {
  isLoading?: false;
  readCount: number;
  content?: string;
  likeCount: number;
  commentCount: number;
  link: string;
}

type PolicyReviewCardProps =
  | PolicyReviewCardLoadingProps
  | PolicyReviewCardLoadedProps;

export const PolicyReviewCard = (props: PolicyReviewCardProps) => {
  if (props.isLoading) {
    return (
      <div className="flex flex-col justify-between rounded-medium pt-18 px-20 pb-16 h-183 w-164 font-400 bg-Gray-white">
        <section className="h-140">
          <div className="flex items-center gap-4">
            <Skeleton className="h-12 w-12" />
            <Skeleton className="h-16 w-80" />
          </div>
          <div className="flex flex-col gap-8 pt-4">
            <Skeleton className="h-16 w-full" />
            <Skeleton className="h-16 w-full" />
            <Skeleton className="h-16 w-full" />
          </div>
        </section>
        <footer>
          <Skeleton className="h-16 w-120" />
        </footer>
      </div>
    );
  }

  return (
    <CustomLink
      aria-label="정책수다 게시글 상세페이지로 이동"
      className="flex flex-col justify-between rounded-medium p-16 h-183 w-164 font-400 bg-Gray-white"
      href={props.link}
    >
      <section className="w-132 h-100">
        <p className="typo-body4 text-Gray-900 font-500 line-clamp-4 break-all pt-4">
          {props.content}
        </p>
      </section>
      <span className="flex items-center gap-4 typo-caption1 text-B-500">
        <SvgVisible className="size-12" />
        {props.readCount.toLocaleString()}명이 보는중
      </span>
      <footer className="items-center typo-caption1 text-Gray-700">
        좋아요 {props.likeCount} · 댓글 {props.commentCount}
      </footer>
    </CustomLink>
  );
};
