'use client';

import { useQuery } from '@tanstack/react-query';

import { LIKE_TYPE } from '@/api';
import { CustomLink } from '@/components/atom/CustomLink';
import { PolicyCard } from '@/components/molecule/PolicyCard';
import { ROUTES } from '@/constants';
import { useLike } from '@/hooks/useLike';
import { policyQueryOptions } from '@/query-factory';
import { useAuthStore } from '@/stores/AuthStore';

export const PeerPopularPolicy = () => {
  const [isLogin, memberJoinStatus] = useAuthStore((state) => [
    state.isLogin,
    state.myInfo?.memberJoinStatus,
  ]);
  const { data, isLoading } = useQuery(
    policyQueryOptions.peerGroupPolicy({ isLogin, memberJoinStatus }),
  );

  const hasNoList = !isLoading && data && data.length < 1;

  const { like } = useLike();

  if (hasNoList) return null;

  return (
    <article>
      <h2 className="typo-body1 font-600 text-Gray-black py-10 flex items-center gap-4 pl-20">
        내 또래가 많이 봤어요
      </h2>
      <ul className="flex flex-col gap-8 px-20">
        {data?.map((policy) => (
          <li key={policy.id}>
            <CustomLink
              href={ROUTES.POLICY_DETAIL.pathname({
                idIdx: policy.meta_policy_id_idx,
              })}
            >
              <PolicyCard
                {...policy}
                benefit={policy.support_benefit}
                endAt={policy.expiration_date}
                idIdx={policy.meta_policy_id_idx}
                imageUrl={policy.agency_logo}
                isLike={policy.like_yn}
                likeCount={policy.like_count}
                name={policy.policy_name}
                provisionList={policy.provision_type_str_list}
                onClickLike={(id) => {
                  like({
                    contentId: id,
                    isLike: policy.like_yn,
                    likeType: LIKE_TYPE.POLICY,
                  });
                }}
              />
            </CustomLink>
          </li>
        ))}
      </ul>
    </article>
  );
};
