import { Button } from '@common/components';
import Image from 'next/image';
import Link from 'next/link';

import { EventPopover } from '@/app/_components/EventPopover';
import { ROUTES } from '@/constants';

export const WelPointSection = () => {
  return (
    <section className="px-20">
      <h2 className="typo-body1 font-600 text-Gray-black py-10 flex items-center gap-4">
        웰포인트를 모으세요
      </h2>
      <ul className="grid grid-cols-3 gap-12">
        <li className="flex flex-col items-center gap-12 bg-Gray-white rounded-large px-12 py-20">
          <Link
            className="typo-body4 font-500 text-Gray-800 flex flex-col items-center gap-8 break-keep text-center min-w-110"
            href={ROUTES.ATTENDANCE_DAILY_CHECK.pathname}
          >
            <Image
              alt="매일 출첵하고"
              height={40}
              src="/assets/pngs/home/scheduled-large.png"
              width={40}
            />
            매일 출첵하고
            <Button className="text-nowrap" dimensions="H32">
              행운도전
            </Button>
          </Link>
        </li>
        <li className="relative flex flex-col items-center gap-12 bg-Gray-white rounded-large px-16 py-20">
          <EventPopover className="-top-12" />
          <Link
            className="typo-body4 font-500 text-Gray-800 flex flex-col items-center gap-8 break-keep text-center min-w-110"
            href={ROUTES.POINTS_INVITE.pathname}
          >
            <Image
              alt="친구 초대하고"
              height={40}
              src="/assets/pngs/home/love-letter.png"
              width={40}
            />
            친구 초대하고
            <Button className="text-nowrap" dimensions="H32">
              500P 받기
            </Button>
          </Link>
        </li>
        <li className="flex flex-col items-center gap-12 bg-Gray-white rounded-large px-16 py-20">
          <Link
            className="typo-body4 font-500 text-Gray-800 flex flex-col items-center gap-8 break-keep text-center min-w-110"
            href={ROUTES.COMMUNITY_CATEGORY.withSearchParams({
              dynamicPath: { category: 'policyTalk' },
            })}
          >
            <Image
              alt="경험 공유하고"
              height={40}
              src="/assets/pngs/home/hand-holding-pen.png"
              width={40}
            />
            경험 공유하고
            <Button className="text-nowrap" dimensions="H32">
              최대 150P
            </Button>
          </Link>
        </li>
      </ul>
    </section>
  );
};
