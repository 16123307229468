import type { MY_LIKED_CONTENTS_TYPE, MY_LIKED_SORT_TYPE } from '@/api';

import { createUuid } from '@common/utils';
import {
  infiniteQueryOptions,
  keepPreviousData,
  queryOptions,
} from '@tanstack/react-query';

import { AUTH_API } from '@/api';
import { queryKeys } from '@/constants/query-keys';

export const myInfoQueryOptionsKeys = {
  listLikeContentsType: createUuid(),
  listLikeContents: createUuid(),
  userActionCount: createUuid(),
};

interface ListLikeContentsTypeProps {
  isLogin?: boolean;
}

interface ListLikeContentsProps {
  isLogin?: boolean;
  contentsType: MY_LIKED_CONTENTS_TYPE;
  likeSort: MY_LIKED_SORT_TYPE;
}

export const myInfoQueryOptions = {
  listLikeContentsType: ({ isLogin }: ListLikeContentsTypeProps) =>
    queryOptions({
      queryKey: [myInfoQueryOptionsKeys.listLikeContentsType, isLogin],
      enabled: isLogin !== undefined,
      queryFn: () => {
        return AUTH_API.getListLikeContentsType();
      },
    }),

  userActionCount: () =>
    queryOptions({
      queryKey: [myInfoQueryOptionsKeys.userActionCount],
      queryFn: () => AUTH_API.getUserActionCount(),
      select: ({ context }) => ({
        memberPolicyCount: context?.member_policy_cnt ?? 0,
        scrapCount: context?.scrap_count ?? 0,
      }),
    }),

  listLikeContents: ({
    isLogin,
    contentsType,
    likeSort,
  }: ListLikeContentsProps) => {
    const INITIAL_PAGE = 1;
    const INITIAL_SIZE = 20;

    return infiniteQueryOptions({
      queryKey: [
        myInfoQueryOptionsKeys.listLikeContents,
        queryKeys.like,
        INITIAL_PAGE,
        INITIAL_SIZE,
        contentsType,
        likeSort,
      ],
      enabled: isLogin !== undefined && contentsType !== undefined,
      queryFn: ({ pageParam = INITIAL_PAGE }) => {
        const params = {
          page: pageParam,
          size: INITIAL_SIZE,
          contentsType,
          likeSort,
        };

        return AUTH_API.getListLikeContents(params);
      },
      getNextPageParam: (lastPage) => {
        const { page: currentPage, total_count: totalCount } =
          lastPage.context ?? {};

        if (!totalCount || !currentPage) return;

        const nextPage = currentPage + 1;
        const totalPageCount = Math.ceil(totalCount / INITIAL_SIZE);

        if (nextPage <= totalPageCount) return nextPage;
      },
      initialData: undefined,
      initialPageParam: INITIAL_PAGE,
      placeholderData: keepPreviousData,
    });
  },
};
