'use client';

import { Suspense } from 'react';

import { Button } from '@common/components';
import { useSuspenseQuery } from '@tanstack/react-query';

import { CustomLink } from '@/components/atom/CustomLink';
import { ROUTES } from '@/constants';
import { pointOptions } from '@/query-factory/point';

import { MotionImage } from '../../MotionImage';

const BenefitCount = () => {
  const { data } = useSuspenseQuery(pointOptions.pointBudget());

  return <>{data.benefitCount.toLocaleString()}</>;
};

const BenefitCountSuspense = () => {
  return (
    <Suspense fallback="-">
      <BenefitCount />
    </Suspense>
  );
};

export const JoinBanner = () => {
  return (
    <section className="flex flex-col items-center bg-Gray-white rounded-medium mx-20 px-20 py-24 gap-16">
      <MotionImage
        alt="support money"
        animate={{ y: [-4, 4] }}
        className="min-h-60"
        src="/assets/pngs/points/gold-coin.png"
        transition={{
          duration: 2,
          repeat: Infinity,
          repeatType: 'reverse',
          ease: 'easeInOut',
        }}
        whileHover={{
          scale: 1.2,
          rotateX: 15,
          rotateY: 15,
          translateZ: 50,
          transition: { duration: 0.4, ease: 'easeOut' },
        }}
        whileTap={{ scale: 0.92 }}
        width="60px"
      />
      <div className="flex flex-col items-center gap-4">
        <p className="typo-body2 font-700 text-Gray-900 text-center">
          2,000P 이상 모으면
          <br />
          출금 가능한 웰포인트!
        </p>
        <small className="typo-body4 font-400 text-Gray-600">
          지금 <BenefitCountSuspense />
          명의 사용자가 웰포인트를 적립했어요.
        </small>
      </div>
      <Button asChild className="bg-B-500 hover:bg-B-600" dimensions="H40">
        <CustomLink href={ROUTES.LOGIN.pathname}>시작하기</CustomLink>
      </Button>
    </section>
  );
};
