import { BANNER_CONTENTS_TYPE } from '@/api';

import { ImportantBannerSection } from '../../../ImportantBannerSection';
import { MainBannerSwiper } from '../MainBannerSwiper';

import { HotTalkSection } from './_components/HotTalkSection';
import { JoinBanner } from './_components/JoinBanner';
import { PolicyPickSection } from './_components/PolicyPickSection';
import { PopularHometownNews } from './_components/PopularHometownNews';
import PopularVoucher from './_components/PopularVoucher';

export const GuestHomeTemplate = () => {
  return (
    <>
      <MainBannerSwiper bannerType={BANNER_CONTENTS_TYPE.HOME_UPPER} />
      <PopularVoucher />
      <PolicyPickSection />
      <HotTalkSection />
      <ImportantBannerSection />
      <JoinBanner />
      <PopularHometownNews />
    </>
  );
};
