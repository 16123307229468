import { BANNER_CONTENTS_TYPE } from '@/api';
import { ImportantBannerSection } from '@/app/(allowed)/ImportantBannerSection';

import { HotTalkSection } from '../GuestHomeTemplate/_components/HotTalkSection';
import { PopularHometownNews } from '../GuestHomeTemplate/_components/PopularHometownNews';
import PopularVoucher from '../GuestHomeTemplate/_components/PopularVoucher';
import { MainBannerSwiper } from '../MainBannerSwiper';

import { HometownPopularPolicy } from './_components/HometownPopularPolicy';
import { InterestedFieldPolicy } from './_components/InterestedFieldPolicy';
import { PeerPopularPolicy } from './_components/PeerPopularPolicy';
import { RecentViewPolicy } from './_components/RecentViewPolicy';
import { SimilarPolicy } from './_components/SimilarPolicy';
import { WelPointSection } from './_components/WelPointSection';

export const MemberHomeTemplate = () => {
  return (
    <>
      <MainBannerSwiper bannerType={BANNER_CONTENTS_TYPE.HOME_UPPER} />
      <PopularVoucher />
      <HotTalkSection />
      <ImportantBannerSection />
      <PopularHometownNews />
      <WelPointSection />
      <HometownPopularPolicy />
      <InterestedFieldPolicy />
      <RecentViewPolicy />
      <PeerPopularPolicy />
      <SimilarPolicy />
    </>
  );
};
